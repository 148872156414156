import { Box, Flex, SimpleGrid, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as Cheesecake } from 'app/core/resources/cheesecake.svg'
import { ReactComponent as CheesecakeLogo } from 'app/core/resources/ckl-logo-white.svg'
import SANDBOX_CUSTODIAL from 'app/core/resources/sandbox-custodial.png'
import SANDBOX_NON_CUSTODIAL from 'app/core/resources/sandbox-non-custodial.png'
import { ReactComponent as StellarLogo } from 'app/core/resources/stellar.svg'

import { ItemCase } from './item-case'

export const HomeTemplate: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Box>
      <Flex pos="fixed" bg="#0058ff" zIndex="99" w="full">
        <Flex
          fill="white"
          maxW="1280px"
          margin="auto"
          px="2rem"
          w="full"
          py="1rem"
          alignItems="center"
        >
          <Flex
            gap={4}
            alignItems="center"
            onClick={(): Window | null => window.open("https://cheesecakelabs.com","_self")}
            cursor="pointer"
          >
            <CheesecakeLogo height="32px" width="32px" />
            <Cheesecake height="64px" width="256px" />
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <Flex
          h="98vh"
          w="100%"
          bg="#0058ff"
          transform="skewY(-12deg)"
          transformOrigin="left"
          flexDir="column"
          px="2rem"
        >
          <Spacer />
          <Flex
            flexDir="column"
            maxW="1280px"
            margin="auto"
            transform="skewY(12deg)"
            w="full"
            mb="7rem"
          >
            <Text
              maxW="1200px"
              mb="2rem"
              fontWeight="bold"
              fontSize={{base: "3rem", md: "4.5rem"}}
              color="white"
              lineHeight="1.1"
            >
              Real-world blockchain solutions powered by Stellar
            </Text>
            <Text
              maxW="697px"
              fontWeight="300"
              fontSize={{base: "1.25rem", md: "1.5rem"}}
              color="white"
              lineHeight="2rem"
            >
              Explore a range of blockchain applications created through our collaboration with the Stellar Development Foundation
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDir="column" maxW="1280px" margin="auto" mb="5rem" mt="2rem"
          px="2rem">
        <SimpleGrid columns={{ md: 2, sm: 1 }} spacing="4rem">
          <ItemCase
            title={
              'Non-custodial Asset Sandbox: Create and manage tokens on Stellar using your Freighter wallet.'
            }
            subTitle={'Non custodial, on Stellar Testnet'}
            bg={'#1e2024'}
            img={SANDBOX_CUSTODIAL}
            onClick={(): Window | null => window.open("https://stellar.cheesecakelabs.com/sandbox/v1","_self")}
          />
          <ItemCase
            title={
              'Custodial Asset Sandbox: An institution-focused platform for effortless asset tokenization with a user-friendly interface.'
            }
            subTitle={'Custodial, on Stellar Testnet'}
            bg={'primary.normal'}
            img={SANDBOX_NON_CUSTODIAL}
            onClick={(): Window | null => window.open("https://stellar.cheesecakelabs.com/sandbox/v2","_self")}
          />
        </SimpleGrid>
      </Flex>

      <Flex
        w="full"
        bg="#1e2024"
        fill="white"
        justifyContent="center"
        py="0.5rem"
        mt="10rem"
      >
        <StellarLogo width="72px" />
      </Flex>
    </Box>
  )
}
