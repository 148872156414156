export const Tag = {
  baseStyle: {
    container: {
      borderRadius: 'full',
      fontSize: 'xs',
      padding: '6px 24px',
      fontWeight: '700',
    },
  },
  variants: {
    blue_sky: {
      label: {
        padding: '6px 12px',
      },
      container: {
        bg: 'blue.100',
        color: 'white',
      },
    },
    blue_moonstone: {
      label: {
        padding: '6px 12px',
      },
      container: {
        bg: 'blue.300',
        color: 'white',
      },
    },
    purple_powder: {
      label: {
        padding: '6px 12px',
      },
      container: {
        bg: 'purple.300',
        color: 'white',
      },
    },
    red: {
      label: {
        padding: '6px 12px',
      },
      container: {
        bg: 'red.500',
        color: 'white',
      },
    },
    light: {
      label: {
        padding: '6px',
      },
      container: {
        border: '1.5px solid',
        borderColor: 'blue.50',
      },
    },
    green: {
      label: {
        padding: '6px 12px',
      },
      container: {
        bg: 'green.500',
        color: 'white',
      },
    },
    actived: {
      label: {
        padding: '6px 12px',
      },
      container: {
        bg: 'primary.normal',
        color: 'white',
      },
    },
  },
}
