export const Checkbox = {
  baseStyle: {
    control: {
      _checked: {
        bg: 'primary.normal',
        borderColor: 'primary.normal',
      },
    },
  },
}
