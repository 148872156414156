import React from 'react'
import { Route } from 'react-router-dom'

import { IModuleRouteProps } from '../types'

const createModuleRoutes = ({
  routePrefix = '',
  routes,
}: IModuleRouteProps): React.ReactNode[] => {
  return routes.map(
    ({ component, path, isPrivate = false, isAuthRedirect = false }) => {
      const routeProps = { path: `${routePrefix}${path}` }

      return <Route {...routeProps} element={React.createElement(component)} key={path}/>
    }
  )
}

export default createModuleRoutes
